import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	CollectionReference,
	Firestore,
	QueryConstraint,
	collection,
	doc,
	docData,
	getDocs,
	limit,
	orderBy,
	query,
	startAfter,
	where,
} from '@angular/fire/firestore';
import { IActivity, IDictionary, trackLeaks } from '@scb-lib/index';
import { UiService } from '@shared/services/ui.service';
import { DateTime, Interval } from 'luxon';
import { Observable, firstValueFrom, from, map, shareReplay, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActivityReaderService {
	scbWebFbFs = inject(Firestore);
	destroyRef = inject(DestroyRef);
	uiSrv = inject(UiService);
	today = DateTime.now().startOf('day');
	todayStr = this.today.toISODate();

	getFirestoreId(): string {
		return doc(collection(this.scbWebFbFs, `foobar`)).id;
	}

	getActivity(id: string): Promise<IActivity | undefined> {
		const d = doc(this.scbWebFbFs, `activities/${id}`);
		return firstValueFrom(docData(d).pipe(map((v) => v as IActivity | undefined)));
	}

	#futureActivities$: Observable<IActivity[]> = from(
		getDocs(query(collection(this.scbWebFbFs, `activities`), orderBy('dateEnd', 'asc'), where('dateEnd', '>=', this.todayStr)))
	).pipe(
		map((v) => v.docs.map((d) => d.data()) as IActivity[]),
		map((v) => v.filter((act) => act.visible) as IActivity[]),
		take(1),
		shareReplay(1),
		takeUntilDestroyed(this.destroyRef)
	);
	getFutureVisibleActivities(): Observable<IActivity[]> {
		return this.#futureActivities$;
	}

	#futureActivitiesVisibleInBanner$: Observable<IActivity[]> = this.#futureActivities$?.pipe(
		map((a) => {
			return a.filter((a) => {
				const interval = Interval.fromDateTimes(
					DateTime.fromISO(a.bannerShowSinceDate).startOf('day'),
					DateTime.fromISO(a.bannerShowUntilDate).endOf('day')
				);
				return interval.contains(this.today);
			});
		}),
		shareReplay(1)
	);
	getFutureActivitiesVisibleInBanner() {
		return this.#futureActivitiesVisibleInBanner$;
	}

	#futureActivitiesVisibleInPopup$: Observable<IActivity[]> = this.#futureActivities$?.pipe(
		map((a) => {
			return a.filter((a) => {
				const interval = Interval.fromDateTimes(
					DateTime.fromISO(a.popupShowSinceDate).startOf('day'),
					DateTime.fromISO(a.popupShowUntilDate).endOf('day')
				);
				return interval.contains(this.today);
			});
		}),
		map((v) => this.filterActivitiesInPopupAlreadyDismissedByUser(v)),
		shareReplay(1)
	);
	getFutureActivitiesVisibleInPopup() {
		return this.#futureActivitiesVisibleInPopup$;
	}

	private filterActivitiesInPopupAlreadyDismissedByUser(acts: IActivity[]): IActivity[] {
		if (this.uiSrv.IS_SERVER) {
			return acts;
		}
		const curStorage: IDictionary<string> = JSON.parse(window.localStorage.getItem('popup_activities') ?? '{}');
		// removes decisions older than 30 days
		Object.keys(curStorage).forEach((key) => {
			const date = DateTime.fromISO(curStorage[key]).startOf('day');
			const diff = this.today.diff(date);
			if (diff.as('days') > 30) {
				delete curStorage[key];
			}
		});
		window.localStorage.setItem('popup_activities', JSON.stringify(curStorage));
		return acts.filter((a) => (curStorage[a.id] ? false : true));
	}

	#futureActivitiesVisibleInList$: Observable<IActivity[]> = this.#futureActivities$?.pipe(
		map((a) => {
			return a.filter((a) => {
				const interval = Interval.fromDateTimes(
					DateTime.fromISO(a.showInListSinceDate).startOf('day'),
					DateTime.fromISO(a.showInListUntilDate).endOf('day')
				);
				return interval.contains(this.today);
			});
		}),
		shareReplay(1)
	);
	getFutureActivitiesVisibleInList() {
		return this.#futureActivitiesVisibleInList$;
	}

	lastVisibleActivities$: Observable<IActivity[]> | undefined;
	getLastVisibleActivities(num = 6): Observable<IActivity[]> {
		if (!this.lastVisibleActivities$) {
			const ref = collection(this.scbWebFbFs, `activities`) as CollectionReference<IActivity>;
			const qc: QueryConstraint[] = [where('visible', '==', true), orderBy('dateEnd', 'desc'), limit(num)];
			this.lastVisibleActivities$ = from(getDocs(query(ref, ...qc))).pipe(
				map((v) => v.docs.map((d) => d.data()) as IActivity[]),
				take(1),
				shareReplay(1)
			);
		}
		return this.lastVisibleActivities$;
	}

	getSomeVisibleActivities(startAfterActivity: IActivity | null | undefined, batchSize: number): Observable<IActivity[]> {
		const ref = collection(this.scbWebFbFs, `activities`) as CollectionReference<IActivity>;
		const qc: QueryConstraint[] = [where('dateEnd', '<', this.todayStr), orderBy('dateEnd', 'desc'), limit(batchSize)];
		if (startAfterActivity) {
			qc.push(startAfter(startAfterActivity.dateEnd));
		}
		return from(getDocs(query(ref, ...qc))).pipe(
			map((v) => v.docs.map((d) => d.data()) as IActivity[]),
			trackLeaks('ActivityReaderService.getSomeActivities'),
			map((v) => v.filter((act) => act.visible) as IActivity[]),
			take(1),
			takeUntilDestroyed(this.destroyRef)
		);
	}
}
